<template>
  <div class="mt-3 px-0">
    <v-card tile class="pb-3" flat>
      <v-row align="center">
        <v-col class="ml-3">
          <v-card-title>Add Time Sheet lol</v-card-title>
        </v-col>
        <v-col>
          <div class="d-flex align-center" style="gap: 20px">
            <v-icon outlined fab @click="goToPrevious">
              mdi mdi-arrow-left-drop-circle-outline</v-icon
            >
            <span style="font-size: 1rem; font-weight: 500">{{
              currentDate
            }}</span>
            <v-icon outlined fab @click="goToNext">
              mdi mdi-arrow-right-drop-circle-outline</v-icon
            >
          </div>
        </v-col>
      </v-row>
      <div class="pa-5">
        <v-card class="pa-3" flat style="background-color: #f0f4f8">
          <v-form @submit.prevent="submitForm">
            <v-row>
              <v-col cols="3">
                <div style="color: #787486" class="font-weight-500">
                  Projects
                </div>
              </v-col>
              <v-col cols="5">
                <div style="color: #787486" class="font-weight-500">
                  Task Detail
                </div>
              </v-col>
              <v-col cols="2">
                <div style="color: #787486" class="font-weight-500">
                  Task Status
                </div>
              </v-col>
              <v-col cols="2">
                <div style="color: #787486" class="font-weight-500">Hours</div>
              </v-col>
            </v-row>
            <v-row
              v-for="(row, index) in taskData"
              :key="row.id"
              class="align-start py-0"
            >
              <v-col cols="3" class="mb-0">
                <v-autocomplete
                  background-color="white"
                  clearable
                  height="60px"
                  solo
                  flat
                  dense
                  label="Select Assign Project"
                  item-text="name"
                  item-value="project_id"
                  :items="allProjects"
                  v-model="row.project_id"
                  :error-messages="errors[`[${index}].project_id`] || ''"
                  @blur="() => removeError(`[${index}].project_id`)"
                  @focus="() => removeError(`[${index}].project_id`)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5" class="px-0">
                <v-textarea
                  solo
                  flat
                  background-color="white"
                  rows="2"
                  v-model="row.task"
                  :error-messages="errors[`[${index}].task`] || ''"
                  @blur="() => removeError(`[${index}].task`)"
                  @focus="() => removeError(`[${index}].task`)"
                ></v-textarea>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  background-color="white"
                  clearable
                  height="60px"
                  solo
                  flat
                  dense
                  label="Select Task Status"
                  item-text="name"
                  item-value="status"
                  :items="status"
                  v-model="row.status"
                  :error-messages="errors[`[${index}].status`] || ''"
                  @blur="() => removeError(`[${index}].status`)"
                  @focus="() => removeError(`[${index}].status`)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <span class="ketan">
                  <v-menu
                    :ref="'menu_' + index"
                    v-model="row.menu2"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scale-transition"
                    :nudge-bottom="65"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        background-color="white"
                        solo
                        flat
                        height="60px"
                        dense
                        placeholder="00:00"
                        v-model="row.time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors[`[${index}].time`] || ''"
                        @blur="() => removeError(`[${index}].time`)"
                        @focus="() => removeError(`[${index}].time`)"
                      ></v-text-field>
                    </template>

                    <v-sheet
                      color="grey-lighten-3"
                      :elevation="12"
                      style="border-radius: 5px"
                    >
                      <div
                        class="d-flex align-center justify-space-between pa-4"
                      >
                        <div
                          style="width: 130px; flex-direction: column"
                          class="d-flex text-center"
                        >
                          <div>
                            <v-icon
                              size="30"
                              @click="increment('hours', index)"
                            >
                              mdi mdi-chevron-up
                            </v-icon>
                          </div>

                          <div class="hoursMinutes">{{ row.hours }}</div>

                          <div>
                            <v-icon
                              size="30"
                              @click="decrement('hours', index)"
                            >
                              mdi mdi-chevron-down
                            </v-icon>
                          </div>
                        </div>
                        <div>:</div>
                        <div
                          style="width: 100px; flex-direction: column"
                          class="text-center"
                        >
                          <div>
                            <v-icon
                              size="30"
                              @click="increment('minutes', index)"
                            >
                              mdi mdi-chevron-up
                            </v-icon>
                          </div>
                          <div class="hoursMinutes">{{ row.minutes }}</div>
                          <div>
                            <v-icon
                              size="30"
                              @click="decrement('minutes', index)"
                            >
                              mdi mdi-chevron-down
                            </v-icon>
                          </div>
                        </div>
                      </div>
                    </v-sheet>
                  </v-menu>
                </span>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-row class="mt-5 d-flex justify-center align-center">
          <v-col cols="3">
            <span class="font-size-14 font-weight-400"
              >Email to : nilesh@nexuslinkservices.com</span
            >
          </v-col>
          <v-col cols="5">
            <div class="d-flex justify-center align-center" style="gap: 20px">
              <div>Cc</div>
              <v-autocomplete
                hide-details=""
                background-color="white"
                multiple
                chips
                clearable
                outlined
                flat
                dense
                label="Select Users"
                item-text="name"
                item-value="id"
                :items="employeesList"
                v-model="emailCc"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col class="d-flex justify-center">
            <span class="font-size-14 font-weight-400">
              Total Hours: {{ convertToTimeString(totalTime) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col class="d-flex justify-start" style="gap: 20px">
            <v-btn depressed color="primary" @click="submitForm">Submit</v-btn>
            <v-btn depressed @click="back" color="secondary">Back</v-btn>
          </v-col>
          <v-spacer />

          <v-col class="d-flex justify-end" style="gap: 20px">
            <v-btn depressed @click="addRow" color="primary">Add Row</v-btn>

            <v-btn
              depressed
              @click="removeRow"
              :disabled="taskData.length <= 1"
              color="secondary"
              >Delete Row</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <!-- for success message -->
      <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        color="green accent-4"
        align-baseline="center"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>

      <!-- for error message -->
      <v-snackbar
        v-model="snackbarError"
        :timeout="3000"
        color="deep-orange accent-4"
        align-baseline="center"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarError = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import moment from "moment/moment";
import * as Yup from "yup";
import { mapMutations, mapState } from "vuex/dist/vuex.common.js";

const validationSchema = Yup.array().of(
  Yup.object().shape({
    project_id: Yup.string(),
    task: Yup.string().when("project_id", {
      is: (val) => !!val,
      then: (schema) => schema.required("Task is required"),
      otherwise: (schema) => schema.nullable(),
    }),

    status: Yup.string().when("project_id", {
      is: (val) => !!val,
      then: (schema) => schema.required("Status is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    time: Yup.string().when("project_id", {
      is: (val) => !!val,
      then: (schema) => schema.required("Time is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  })
);
export default {
  data() {
    return {
      currentDate: moment().format("DD-MM-YYYY"),
      taskData: [
        {
          id: Math.random().toString(36).substring(2, 7),
          project_id: "",
          task: null,
          status: null,
          time: null,
          hours: 0,
          minutes: 0,
          menu2: false,
          modal2: false,
        },
        {
          id: Math.random().toString(36).substring(2, 7),
          project_id: "",
          task: null,
          status: null,
          time: null,
          menu2: false,
          modal2: false,
          hours: 0,
          minutes: 0,
        },
        {
          id: Math.random().toString(36).substring(2, 7),
          project_id: "",
          task: null,
          status: null,
          time: null,
          menu2: false,
          modal2: false,
          hours: 0,
          minutes: 0,
        },
      ],
      snackbar: false,
      snackbarError: false,
      message: "",
      employees: [],
      emailCc: [],
      totalTime: 0,
      errors: {},
      selectedTime: null,

      status: [
        { name: "Pending", status: 0 },
        { name: "Completed", status: 1 },
      ],
    };
  },
  computed: {
    ...mapState("projects", ["allProjects"]),
    ...mapState("employees", ["employeesList", "cachesEmpData"]),
  },

  watch: {
    taskData: {
      handler(newVal) {
        this.totalTime = 0;
        newVal.forEach((row, index) => {
          this.totalTime =
            this.totalTime +
            this.convertToMinutes(`${row.hours}:${row.minutes}`);
          console.log(row.hours, row.hours.toString().length, "aaaaaa");
          this.timeChange(
            `${row.hours.toString().length < 2 ? "0" + row.hours : row.hours}:${
              row.minutes.toString().length < 2
                ? "0" + row.minutes
                : row.minutes
            }`,
            index
          );
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("projects", ["setProjectData"]),
    ...mapMutations("employees", ["setEmployees", "isCachesEmpData"]),
    increment(field, index) {
      if (field === "hours") {
        this.taskData[index].hours = (this.taskData[index].hours + 1) % 24;
      } else if (field === "minutes") {
        this.taskData[index].minutes += 15;
        if (this.taskData[index].minutes >= 60) {
          this.taskData[index].minutes = 0;
          this.taskData[index].hours = (this.taskData[index].hours + 1) % 24;
        }
      }
    },
    decrement(field, index) {
      if (field === "hours") {
        this.taskData[index].hours = (this.taskData[index].hours - 1 + 24) % 24;
      } else if (field === "minutes") {
        this.taskData[index].minutes -= 15;
        if (this.taskData[index].minutes < 0) {
          this.taskData[index].minutes = 45;
          this.taskData[index].hours =
            (this.taskData[index].hours - 1 + 24) % 24;
        }
      }
    },

    back() {
      this.$router.back("/asdd");
    },
    goToNext() {
      const nextDate = moment(this.currentDate, "DD-MM-YYYY").add(1, "days");
      if (nextDate.date() === 1) {
        nextDate.add(1, "days").startOf("month");
      }
      this.currentDate = nextDate.format("DD-MM-YYYY");
    },
    goToPrevious() {
      const prevDate = moment(this.currentDate, "DD-MM-YYYY").subtract(
        1,
        "days"
      );
      if (
        prevDate.date() ===
        moment(this.currentDate, "DD-MM-YYYY").startOf("month").date()
      ) {
        prevDate.startOf("month");
      }
      this.currentDate = prevDate.format("DD-MM-YYYY");
    },
    timeChange(e, index) {
      this.taskData[index]["time"] = e;
    },
    convertToMinutes(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    },
    convertToTimeString(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours.toString().padStart(1, "0")}:${minutes
        .toString()
        .padStart(1, "0")}`;
    },

    addRow() {
      this.taskData.push({
        id: Math.random().toString(36).substring(2, 7),
        project_id: "",
        task: "",
        status: null,
        time: null,
        menu2: false,
        modal2: false,
        hours: 0,
        minutes: 0,
      });
    },
    removeRow() {
      this.taskData.splice(this.taskData.length - 1, 1);
    },
    async getEstimatedTimeSheet() {
      this.$axios.get("estimated-time-sheet").then((res) => {
        if (res.data.success) {
          console.log(res.data.timeSheetDetails);
          let timeSheetDetailsArr = res.data.timeSheetDetails;
          if (timeSheetDetailsArr.length) this.taskData.length = 0;
          for (let index = 0; index < timeSheetDetailsArr.length; index++) {
            const element = timeSheetDetailsArr[index];
            this.taskData.push({
              id: element.id,
              project_id: element.project_id,
              task: element.description,
              status: null,
              time: element?.time || null,
              menu2: false,
              modal2: false,
              hours: element?.time?.split(":")[0] || 0,
              minutes: element?.time?.split(":")[1] || 0,
            });
          }
        } else {
          this.snackbarError = true;
          this.message = res.data.message;
        }
      });
    },
    updateRow(index) {
      if (!this.taskData[index].project) {
        this.taskData[index].task = "";
        this.taskData[index].status = "";
        this.taskData[index].time = "";
      }
    },
    getErrors(field) {
      return this.errors.filter((err) => err.includes(field));
    },
    removeError(key) {
      delete this.errors[key];
    },

    redirect() {
      this.$router.push({
        path: "/timeSheet",
      });
    },

    // ** API services for add Time Sheet
    async submitForm() {
      validationSchema
        .validate(this.taskData, { abortEarly: false })
        .then(() => {
          this.errors = {};

          // Payload for API

          const tempData = this.taskData
            .filter(
              (d) => d.project_id && d.task && (d.status == 0 || 1) && d.time
            )
            .map((d) => ({
              project_id: d.project_id,
              status: d.status,
              time: this.convertToMinutes(d.time),
              task: d.task,
            }));

          this.$axios
            .post("/time-sheet", {
              data: tempData,
              date: moment(this.currentDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              emailCc: this.emailCc,
            })
            .then((res) => {
              this.loading = false;

              if (res.data.success) {
                this.snackbar = true;
                this.message = res.data.message;

                setTimeout(() => {
                  this.redirect();
                }, 2000);
              } else {
                this.snackbarError = true;
                this.message = res.data.message;
              }
            });

          // login the user
        })
        .catch((err) => {
          err?.inner?.forEach((error) => {
            this.errors = {
              ...this.errors,

              [error.path]: error.message,
            };
          });
        });
    },
    get_project_list() {
      if (this.allProjects.length) return;
      this.loading = true;
      this.$axios.get("employee/project").then((res) => {
        this.setProjectData(res.data.projects);
        this.loading = false;
      });
    },
    getEmployeeList() {
      if (this.employeesList.length) return;
      let _self = this;
      this.loading = true;
      this.$axios.get("employee").then((res) => {
        this.loading = false;

        if (res.data.success) {
          _self.setEmployees(res.data.employees);
        } else {
          this.snackbarError = true;
          _self.message = res.data.message;
        }
      });
    },
  },
  mounted() {
    this.get_project_list();
    this.getEmployeeList();
    this.getEstimatedTimeSheet();
  },
};
</script>

<style l>
/* .ketan-time-picker .v-picker__body {
  max-height: 180px !important;
}
.ketan-time-picker .v-picker__body .v-time-picker-clock__item {
  font-size: 12px;
  width: 20px;
  height: 20px;
} */

.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-color: #f4f6f7;
}
.hoursMinutes {
  border: 2px solid #b7b7b7 !important;
  padding: 5px 30px 5px 30px !important;
  border-radius: 5px !important;
}
</style>
